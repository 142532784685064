<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="个人信息"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<van-row>
			<div class="form_cell_item">
			  <div class="form_cell_label">姓名:</div>
			  <div class="ipt_wrap">{{userinfo.realname||''}}</div>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">会员账号:</div>
			  <div class="ipt_wrap">{{userinfo.user||''}}</div>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">当前密码:</div>
			  <input placeholder="当前登陆密码" type="password" maxlength="30" v-model="passform.oldpass"/>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">新密码:</div>
			  <input placeholder="输入新密码" type="password" maxlength="30" v-model="passform.password"/>
			</div>
			<div class="form_cell_item">
			  <div class="form_cell_label">确认密码:</div>
			  <input placeholder="请重新输入新密码" type="password" maxlength="30" v-model="passform.repassword"/>
			</div>
			<van-button type="primary" 
			:disabled="iscanlogin"
			style="width: 90%;margin: 30px auto;background: #ff571e;border: none;" 
				@click="saveorder">
				确认
			</van-button>
		</van-row>
    </div>
	<tabbar/>
  </div>
</template>

<script>
	import tabbar from '../../components/tabbar.vue';
export default {
	components:{tabbar},
  data() {
    return {
		userinfo:{},
		passform:{
			oldpass:"",
			password:"",
			repassword:"",
		}
    }
  },
  computed:{
		iscanlogin(){
			return this.passform.oldpass==''||this.passform.password==''||this.passform.repassword==''
		},
  },
  created() {
	this.getinfo();
  },
  mounted() {},
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
          this.userinfo = data.data.user
        }
      }
    },
	  async saveorder(){
		  if (!this.passform.oldpass) {
		    this.$toast.fail('请输入当前密码');
		    return false
		  }
		  if (!this.passform.password) {
		    this.$toast.fail('请输入新密码');
		    return false
		  }
		  if (!this.passform.repassword) {
		    this.$toast.fail('请输入确认密码');
		    return false
		  }
		  if (this.passform.repassword!=this.passform.password) {
		    this.$toast.fail('两次密码输入不一致');
		    return false
		  }
		  const postform = Object.assign({type:1},this.passform);
		  const { data } = await this.$http.post('/home/home/changepass', postform)
		  if (data) {
		    if (data.code === 200) {
		      this.$toast.success('操作成功')
		      this.passform = {
					oldpass:"",
					repassword:"",
					password:""
		      }
			  this.$router.back();
		    } else {
		      this.$toast.fail(data.msg)
		    }
		  }
	  },
  }
}
</script>
<style lang="less" scoped>
	.ipt_tips{
		text-align: left;
		padding-left: calc(135px + 0.6rem);
		color: #8686aa;
	}
		.form_cell_item{
			padding: 0.6rem;
			display: flex;
			align-items: center;
			position: relative;
			&::after {
			    content: " ";
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    right: 0;
			    height: 0.026667rem;
			    border-bottom: 0.026667rem solid #e5e5e5;
			    color: #e5e5e5;
			    -webkit-transform-origin: 0 100%;
			    -ms-transform-origin: 0 100%;
			    transform-origin: 0 100%;
			    -webkit-transform: scaleY(0.5);
			    -ms-transform: scaleY(0.5);
			    transform: scaleY(0.5);
			    z-index: 2;
			}
			.form_cell_label{
				width: 115px;
				font-size: 13px;
				color: #cad0e6;
				text-align: left;
			}
			input,.ipt_wrap{
				text-align: left;
				width: 100%;
				flex: 1;
				padding:10px 20px;
				box-sizing: border-box;
				background-color: transparent;
				color: #cad0e6;
				outline: none;
				font-size: 18px;
				border: none;
				min-height: 41px;
			}
		}
	#unitDe{
		border: 0.026667rem solid #ff571e;
		height: 3.5rem;
		box-sizing: border-box;
		display: block;
		width: 95%;
		color: #ff571e;
		border-radius: 0.613333rem;
		padding: 0.613333rem;
		margin: 0.413333rem;
		outline: 0;
		-webkit-appearance: none;
		background-color: transparent;
	}
	.info_item{
		color: #cad0e6;
		border: 0.026667rem solid #8686aa;
		width: calc(100% - 0.893333rem);
		margin: auto;
		margin-top: 2.666667rem;
		padding: 0.513333rem 0.4rem;
		border-radius: 0.513333rem;
	}
	
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
}
</style>
